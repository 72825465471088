// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes word-list_spin_1EV7C{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.word-list_root_2SKjv>*{margin-bottom:1.5625rem}.word-list_root_2SKjv>*:last-child{margin-bottom:0rem}", ""]);
// Exports
exports.locals = {
	"root": "word-list_root_2SKjv",
	"spin": "word-list_spin_1EV7C"
};
module.exports = exports;
