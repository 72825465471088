// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes page_spin_1nuNl{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.page_root_brVOc{display:flex;flex-flow:column;min-height:100vh}.page_spacer_3xYCn{width:calc(100% - 1.5625rem * 2);margin-top:1.25rem;margin-bottom:3.75rem;margin-right:1.5625rem;margin-left:1.5625rem;padding:0rem;box-sizing:border-box;flex:1}@media screen and (max-width:640px){.page_spacer_3xYCn{width:100%;margin-right:0rem;margin-left:0rem}}.page_content_12c-v{width:100%;max-width:56.25rem;margin:0rem auto}", ""]);
// Exports
exports.locals = {
	"root": "page_root_brVOc",
	"spacer": "page_spacer_3xYCn",
	"content": "page_content_12c-v",
	"spin": "page_spin_1nuNl"
};
module.exports = exports;
