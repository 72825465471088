// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes radio-group_spin_3f2VP{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.radio-group_root_llLJZ{display:flex;flex-direction:row;justify-content:center}.radio-group_root_llLJZ>*{margin:0rem 0rem 0rem 0.9375rem}.radio-group_root_llLJZ>*:first-child{margin:0rem}", ""]);
// Exports
exports.locals = {
	"root": "radio-group_root_llLJZ",
	"spin": "radio-group_spin_3f2VP"
};
module.exports = exports;
