// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes suggestion-list_spin_21WBr{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.suggestion-list_root_2U7Nw{background:linear-gradient(to right bottom,rgba(255,255,255,0.1),rgba(255,255,255,0.19));box-shadow:0rem 0rem 0rem 0.0625rem #9da9ad,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset;margin:0rem;padding:0.9375rem 1.5625rem;border-radius:0rem}@media screen and (max-width:640px){.suggestion-list_root_2U7Nw{padding:0.9375rem 0.9375rem}}.suggestion-list_list_EOJxU{margin:0rem;padding:0rem;list-style:none}", ""]);
// Exports
exports.locals = {
	"root": "suggestion-list_root_2U7Nw",
	"list": "suggestion-list_list_EOJxU",
	"spin": "suggestion-list_spin_21WBr"
};
module.exports = exports;
