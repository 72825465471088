// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes search-form_spin_tC_1F{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.search-form_root_jS1s8{background:linear-gradient(to right bottom,rgba(255,255,255,0.1),rgba(255,255,255,0.19));box-shadow:0rem 0rem 0rem 0.0625rem #9da9ad,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset;margin:0rem;padding:1.5625rem 1.5625rem;line-height:1.6;border-radius:0rem}@media screen and (max-width:640px){.search-form_root_jS1s8{padding:1.5625rem 0.9375rem}}.search-form_radio-wrapper_35LT8{margin:0.3125rem 0rem 0rem 0rem;display:flex;flex-direction:column;align-items:center}", ""]);
// Exports
exports.locals = {
	"root": "search-form_root_jS1s8",
	"radio-wrapper": "search-form_radio-wrapper_35LT8",
	"spin": "search-form_spin_tC_1F"
};
module.exports = exports;
