// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes commission-page_spin_3vOC4{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.commission-page_header_1v8p_{margin:0rem 0rem 2.5rem 0rem}", ""]);
// Exports
exports.locals = {
	"header": "commission-page_header_1v8p_",
	"spin": "commission-page_spin_3vOC4"
};
module.exports = exports;
