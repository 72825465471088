// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes commission-form_spin_2C9uS{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.commission-form_root_3ZV4H{background:linear-gradient(to right bottom,rgba(255,255,255,0.1),rgba(255,255,255,0.19));box-shadow:0rem 0rem 0rem 0.0625rem #9da9ad,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset;margin:0rem;padding:1.5625rem 1.5625rem;line-height:1.6;border-radius:0rem}@media screen and (max-width:640px){.commission-form_root_3ZV4H{padding:1.5625rem 0.9375rem}}", ""]);
// Exports
exports.locals = {
	"root": "commission-form_root_3ZV4H",
	"spin": "commission-form_spin_2C9uS"
};
module.exports = exports;
