// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes suggestion-pane_spin_3W-4t{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.suggestion-pane_suggestion_aF_Cl{margin:0px;margin-left:0.75rem;line-height:1.6}.suggestion-pane_suggestion_aF_Cl::before{font-family:\"Font Awesome 5 Pro\",\"Font Awesome 5 Free\",\"Font Awesome 5 Brands\",\"FontAwesome\";font-size:90%;font-weight:900;width:0.375rem;margin-right:0.375rem;margin-left:-0.75rem;color:#33C3FF;content:\"\\F0DA\";display:inline-block}.suggestion-pane_keyword_10mwN{margin-left:0.375rem}.suggestion-pane_divider_1nMUY{margin:0rem 0.375rem;color:rgba(255,255,255,0.5)}.suggestion-pane_link_3VjIZ{color:#FFAB33;text-decoration:underline;cursor:pointer}.suggestion-pane_link_3VjIZ:hover{color:#ffc470}.suggestion-pane_sans_Q6_9E{font-family:\"Inter Web\",\"Inter\",\"Arial Local\",\"Arial\",\"BIZ UDPGothic\",\"Yu Gothic Medium\",\"Yu Gothic\";font-size:96%;line-height:96%}", ""]);
// Exports
exports.locals = {
	"suggestion": "suggestion-pane_suggestion_aF_Cl",
	"keyword": "suggestion-pane_keyword_10mwN",
	"divider": "suggestion-pane_divider_1nMUY",
	"link": "suggestion-pane_link_3VjIZ",
	"sans": "suggestion-pane_sans_Q6_9E",
	"spin": "suggestion-pane_spin_3W-4t"
};
module.exports = exports;
