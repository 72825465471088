// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes logo_spin_8Hi-B{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.logo_root_398JO{display:flex;align-items:flex-start;justify-content:center}.logo_logo_2H32P{width:7.5rem;margin-right:0.9375rem}@media screen and (max-width:640px){.logo_logo_2H32P{width:5.25rem;margin-right:0.4375rem}}.logo_text-container_3PQjL{display:flex;flex-direction:column;align-items:center}.logo_text_2yXn1{margin-top:2.8125rem;font-family:\"Gilit Web\";font-size:3.25rem;cursor:pointer}@media screen and (max-width:640px){.logo_text_2yXn1{margin-top:1.96875rem;font-size:2.275rem}}.logo_orange_2UgYL{color:#FFAB33}.logo_blue_fZJBQ{color:#33C3FF}.logo_language_UtR-z{margin-top:0.9375rem;display:flex}.logo_locale_3NOJv{background:linear-gradient(to right bottom,rgba(255,255,255,0.1),rgba(255,255,255,0.19));box-shadow:0rem 0rem 0rem 0.0625rem #9da9ad,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset;margin:0rem 0rem 0rem 0.625rem;padding:0.3125rem 0.625rem;border-radius:0rem;display:flex;cursor:pointer;align-items:center;justify-content:center}.logo_locale_3NOJv:hover{background:linear-gradient(to right bottom,rgba(255,171,51,0.15),rgba(255,171,51,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #c28a33,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}.logo_locale_3NOJv:first-child{margin:0rem}.logo_locale_3NOJv.logo_selected_1UxyV{background:linear-gradient(to right bottom,rgba(51,195,255,0.15),rgba(51,195,255,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #299ccc,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}.logo_locale_3NOJv.logo_selected_1UxyV:hover{background:linear-gradient(to right bottom,rgba(51,195,255,0.15),rgba(51,195,255,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #299ccc,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}", ""]);
// Exports
exports.locals = {
	"root": "logo_root_398JO",
	"logo": "logo_logo_2H32P",
	"text-container": "logo_text-container_3PQjL",
	"text": "logo_text_2yXn1",
	"orange": "logo_orange_2UgYL",
	"blue": "logo_blue_fZJBQ",
	"language": "logo_language_UtR-z",
	"locale": "logo_locale_3NOJv",
	"selected": "logo_selected_1UxyV",
	"spin": "logo_spin_8Hi-B"
};
module.exports = exports;
