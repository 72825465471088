// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes checkbox_spin_20n2_{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.checkbox_checkbox_uA9Yr{display:none}.checkbox_checkbox_uA9Yr+.checkbox_label_1z1r0{padding:0em 0em 0em calc(1em + 0.375rem);position:relative;cursor:pointer}.checkbox_checkbox_uA9Yr+.checkbox_label_1z1r0::before{top:0.1em;left:0em;width:1em;height:1em;border:0.0625rem #9da9ad solid;border-radius:0rem;background:repeating-linear-gradient(-45deg,#0a2733 0.625rem,#0a2632 0.625rem,#0a2632 1.25rem,#0a2733 1.25rem,#0a2733 1.875rem,#0a2632 1.875rem);background-attachment:fixed;box-shadow:0rem 0.125rem 0.375rem rgba(0,0,0,0.3) inset;box-sizing:border-box;display:inline-block;vertical-align:middle;position:absolute;content:\"\"}.checkbox_root_2pRU1:hover .checkbox_checkbox_uA9Yr+.checkbox_label_1z1r0::before{border:0.0625rem #c28a33 solid}.checkbox_checkbox_uA9Yr:checked+.checkbox_label_1z1r0::before{border:0.0625rem #299ccc solid}.checkbox_root_2pRU1:hover .checkbox_checkbox_uA9Yr:checked+.checkbox_label_1z1r0::before{border:0.0625rem #299ccc solid}.checkbox_checkbox_uA9Yr:checked+.checkbox_label_1z1r0::after{font-family:\"Font Awesome 5 Pro\",\"Font Awesome 5 Free\",\"Font Awesome 5 Brands\",\"FontAwesome\";font-size:90%;font-weight:900;top:0.1em;left:0.15em;font-size:80%;color:#33C3FF;box-sizing:border-box;display:block;position:absolute;content:\"\\F00C\"}", ""]);
// Exports
exports.locals = {
	"checkbox": "checkbox_checkbox_uA9Yr",
	"label": "checkbox_label_1z1r0",
	"root": "checkbox_root_2pRU1",
	"spin": "checkbox_spin_20n2_"
};
module.exports = exports;
