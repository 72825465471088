// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes input_spin_1wL85{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.input_root_vVLAm{margin:0rem;padding:0.3125rem 0.9375rem;background:repeating-linear-gradient(-45deg,#0a2733 0.625rem,#0a2632 0.625rem,#0a2632 1.25rem,#0a2733 1.25rem,#0a2733 1.875rem,#0a2632 1.875rem);background-attachment:fixed;border:0.0625rem #9da9ad solid;box-shadow:0rem 0.125rem 0.375rem rgba(0,0,0,0.3) inset;border-radius:0rem}.input_root_vVLAm:hover,.input_root_vVLAm:focus-within{border:0.0625rem #c28a33 solid}.input_input-inner_1VobG{width:100%;margin:0rem;padding:0rem;font-family:inherit;font-size:inherit;color:inherit;line-height:inherit;background:none;border:none;outline:none;flex-grow:1}", ""]);
// Exports
exports.locals = {
	"root": "input_root_vVLAm",
	"input-inner": "input_input-inner_1VobG",
	"spin": "input_spin_1wL85"
};
module.exports = exports;
