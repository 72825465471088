// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes pagination_spin_1yjdb{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.pagination_root_2VN-v{display:flex;flex-direction:row}.pagination_left_1C5gL{width:100%;margin:0rem 0.625rem 0rem 0rem;text-align:right;flex-grow:1}.pagination_right_47OOb{width:100%;margin:0rem 0rem 0rem 0.625rem;text-align:left;flex-grow:1}@media screen and (max-width:640px){.pagination_left_1C5gL .pagination_group_36gMu,.pagination_right_47OOb .pagination_group_36gMu{display:none}}.pagination_leftmost_3Xiit,.pagination_rightmost_HvkK5{white-space:nowrap}.pagination_group_36gMu{display:inline-block}.pagination_group_36gMu>*{margin:0rem 0rem 0rem 0.625rem}.pagination_group_36gMu>*:first-child{margin:0rem}.pagination_button_3M6a0{background:linear-gradient(to right bottom,rgba(255,255,255,0.1),rgba(255,255,255,0.19));box-shadow:0rem 0rem 0rem 0.0625rem #9da9ad,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset;width:2.625rem;padding:0.625rem 0rem;border-radius:0rem;display:inline-flex;cursor:pointer;align-items:center;justify-content:center}.pagination_button_3M6a0:hover{background:linear-gradient(to right bottom,rgba(255,171,51,0.15),rgba(255,171,51,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #c28a33,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}.pagination_button_3M6a0.pagination_icon_1IUDD{font-family:\"Font Awesome 5 Pro\",\"Font Awesome 5 Free\",\"Font Awesome 5 Brands\",\"FontAwesome\";font-size:90%;font-weight:900;width:2.25rem;font-size:100%}.pagination_center_39gUY .pagination_button_3M6a0{background:linear-gradient(to right bottom,rgba(51,195,255,0.15),rgba(51,195,255,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #299ccc,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}.pagination_center_39gUY .pagination_button_3M6a0:hover{background:linear-gradient(to right bottom,rgba(51,195,255,0.15),rgba(51,195,255,0.235));box-shadow:0rem 0rem 0rem 0.0625rem #299ccc,0rem 0rem 0.1875rem 0.0625rem rgba(10,39,51,0.4) inset}", ""]);
// Exports
exports.locals = {
	"root": "pagination_root_2VN-v",
	"left": "pagination_left_1C5gL",
	"right": "pagination_right_47OOb",
	"group": "pagination_group_36gMu",
	"leftmost": "pagination_leftmost_3Xiit",
	"rightmost": "pagination_rightmost_HvkK5",
	"button": "pagination_button_3M6a0",
	"icon": "pagination_icon_1IUDD",
	"center": "pagination_center_39gUY",
	"spin": "pagination_spin_1yjdb"
};
module.exports = exports;
