// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes loading_spin_GMcVo{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.loading_root_2VQHg{width:100%;text-align:center}.loading_spinner_3txZR{font-family:\"Font Awesome 5 Pro\",\"Font Awesome 5 Free\",\"Font Awesome 5 Brands\",\"FontAwesome\";font-size:90%;font-weight:900;width:1em;height:1em;font-size:2rem;color:#33C3FF;display:inline-flex;align-items:center;justify-content:center;animation:loading_spin_GMcVo 1s steps(8,end) infinite}.loading_spinner_3txZR::before{content:\"\\F110\"}", ""]);
// Exports
exports.locals = {
	"root": "loading_root_2VQHg",
	"spinner": "loading_spinner_3txZR",
	"spin": "loading_spin_GMcVo"
};
module.exports = exports;
