// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes dictionary-page_spin_SzpTA{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.dictionary-page_header_1eemA{margin:0rem 0rem 2.5rem 0rem}.dictionary-page_search-form_2My7j{margin:0rem 0rem 2.5rem 0rem}.dictionary-page_suggestion-list_1_9FK,.dictionary-page_word-list_CAdB8{margin:0rem 0rem 1.5625rem 0rem}.dictionary-page_pagination_3Q944{margin:0rem}@media screen and (max-width:640px){.dictionary-page_pagination_3Q944{margin:0rem 0.9375rem}}", ""]);
// Exports
exports.locals = {
	"header": "dictionary-page_header_1eemA",
	"search-form": "dictionary-page_search-form_2My7j",
	"suggestion-list": "dictionary-page_suggestion-list_1_9FK",
	"word-list": "dictionary-page_word-list_CAdB8",
	"pagination": "dictionary-page_pagination_3Q944",
	"spin": "dictionary-page_spin_SzpTA"
};
module.exports = exports;
